import router from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Loading from '@/components/atoms/Loading';
import { Layout } from '@/components/templates/Layout';
import useGym from '@/hooks/useGym';
import { tokenSelector } from '@/store/selector/adminSelector';

const Gymx = () => {
  const { selectedGym, gymList } = useGym();
  const tokenState = useSelector(tokenSelector);

  useEffect(() => {
    if (tokenState === '' || gymList.length === 0) {
      router.push('/auth/login');
      return;
    }
    if (selectedGym === undefined && 0 < gymList.length) {
      router.push(`/${gymList[0].id}`);
      return;
    }
  }, [gymList, selectedGym, tokenState]);

  return (
    <Layout>
      <Container>
        <Loading size={32} />
      </Container>
    </Layout>
  );
};

export default Gymx;

const Container = styled('div')`
  display: flex;
  justify-content: center;
  padding: 0 12px;
  ${({ theme }) => theme.breakpoints.lg`
    padding: 0 60px;
  `};
  height: 100%;
  align-items: center;
`;
